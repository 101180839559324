import { Injectable, isDevMode } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { Writer, ChangeUrgencyWriter, Dealer, Book, ChangeUrgencyBook, GeneralDB } from './interfaces';
import { State } from './reducers';
import { Store, select } from '@ngrx/store';
import { LocalDbNames, LocationPath, RemoteDbNames } from './enums';
import { Router } from '@angular/router';
import { ExchangeRateService } from './exchange-rate-service.service';
import { createRxDatabase, addRxPlugin, RxCollection } from 'rxdb';
import { replicateCouchDB } from 'rxdb/plugins/replication-couchdb';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { wrappedValidateAjvStorage } from 'rxdb/plugins/validate-ajv';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { bookSchema, dealerSchema, generalSchema, writerSchema } from './schemas';

addRxPlugin(RxDBQueryBuilderPlugin);
if (isDevMode()) {
    addRxPlugin(RxDBDevModePlugin);
}

const storage = wrappedValidateAjvStorage({
    storage: getRxStorageDexie()
});

@Injectable({
    providedIn: 'root'
})
export class DBService {
    writersFromDB = new Subject<Writer[]>();

    localWritersDB: RxCollection<Writer>;
    localDealersDB: RxCollection<Dealer>;
    localBooksDB: RxCollection<Book>;
    localGeneralDB: RxCollection<GeneralDB>;

    urgencyWritersList: ChangeUrgencyWriter[];
    urgencyWritersList$Subscription: Subscription;
    urgencyWritersList$: Observable<ChangeUrgencyWriter[]> = this.store$.pipe(
        select('writers', 'urgencyWritersList')
    );

    urgencyBookList: ChangeUrgencyBook[];
    urgencyBookList$Subscription: Subscription;
    urgencyBookList$: Observable<ChangeUrgencyBook[]> = this.store$.pipe(
        select('writers', 'urgencyBookList')
    );

    public readonly dbReady: Promise<void>;

    constructor(
        private readonly store$: Store<State>,
        private readonly router: Router,
        private readonly exchangeRateService: ExchangeRateService,
    ) {
        this.urgencyWritersList$Subscription = this.urgencyWritersList$.subscribe((writersList) => this.urgencyWritersList = writersList);
        this.urgencyBookList$Subscription = this.urgencyBookList$.subscribe((bookList) => this.urgencyBookList = bookList);
        this.dbReady = this.initDB();
    }

    async initDB() {
        const db = await createRxDatabase({
            name: 'ashurisdb',
            storage: getRxStorageDexie({}),
            multiInstance: true,
            eventReduce: true,
        });

        const collections = await db.addCollections<{
            [LocalDbNames.WRITERS]: RxCollection<Writer>,
            [LocalDbNames.DEALERS]: RxCollection<Dealer>,
            [LocalDbNames.BOOKS]: RxCollection<Book>,
            [LocalDbNames.GENERAL]: RxCollection<GeneralDB>
        }>({
            [LocalDbNames.WRITERS]: {
                schema: writerSchema,
            },
            [LocalDbNames.DEALERS]: {
                schema: dealerSchema,
            },
            [LocalDbNames.BOOKS]: {
                schema: bookSchema,
            },
            [LocalDbNames.GENERAL]: {
                schema: generalSchema,
            }
        });

        this.localWritersDB = collections[LocalDbNames.WRITERS];
        this.localDealersDB = collections[LocalDbNames.DEALERS];
        this.localBooksDB = collections[LocalDbNames.BOOKS];
        this.localGeneralDB = collections[LocalDbNames.GENERAL];

        await this.syncAllDBS();
    }

    async syncAllDBS() {
        try {
            await this.syncDb(this.localWritersDB, RemoteDbNames.WRITERS);
            await this.syncDb(this.localDealersDB, RemoteDbNames.DEALERS);
            await this.syncDb(this.localBooksDB, RemoteDbNames.BOOKS);
            await this.syncDb(this.localGeneralDB, RemoteDbNames.GENERAL);
        } catch (error) {
            console.error('Error syncing databases:', error);
        }
    }

    async syncDb<T>(localDb: RxCollection<T>, remoteDbUrl: string) {
        try {
            replicateCouchDB({
                collection: localDb,
                url: remoteDbUrl,
                live: true,
                retryTime: 5000,
                replicationIdentifier: remoteDbUrl,
                pull: {
                    batchSize: 60,
                    heartbeat: 60000
                },
                push: {
                    batchSize: 60,
                }
            });
        } catch (error) {
            console.error('Error syncing database:', error);
        }
    }

    async removeItem(localDbName: LocalDbNames, item: Writer | Book | Dealer) {
        await this.dbReady;
        try {
            const formatLocalDbName = localDbName.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase()).replace(/Db$/, 'DB');
            const db = this[formatLocalDbName];
            const existingItem = await db.findOne(item._id).exec();
            if (existingItem) {
                await db.remove(item._id);
                this.router.navigate([LocationPath.REMOVE_ITEM]);
            } else {
                console.warn('Item not found for removal:', item);
            }
        } catch (error) {
            console.error('Error removing item:', error);
        }
    }

    async fetchExchangeRates(): Promise<void> {
        await this.dbReady;
        try {
            const exchangeRates = await this.exchangeRateService.getExchangeRate().toPromise();
            const timestamp = new Date().toISOString();
            const doc: GeneralDB = {
                _id: 'exchange_rates',
                type: 'exchange_rate_data',
                itemName: JSON.stringify({
                    data: exchangeRates,
                    timestamp: timestamp
                })
            };
            await this.localGeneralDB.upsert(doc);
        } catch (error) {
            console.error('Error fetching exchange rates:', error);
        }
    }

    async getExchangeRates(): Promise<number> {
        await this.dbReady;
        try {
            const doc = await this.localGeneralDB.findOne('exchange_rates').exec();
            if (doc) {
                const storedData = JSON.parse(doc.itemName);
                const storedTimestamp = new Date(storedData.timestamp);
                const currentTime = new Date();
                const currentTimeUTC = new Date(currentTime.toISOString());
                const oneHour = 60 * 60 * 1000;

                if ((currentTimeUTC.getTime() - storedTimestamp.getTime()) < oneHour) {
                    return storedData.data;
                } else {
                    await this.fetchExchangeRates();
                    return this.getExchangeRates();
                }
            } else {
                await this.fetchExchangeRates();
                return this.getExchangeRates();
            }
        } catch (error) {
            console.error('Error getting exchange rates:', error);
            return null;
        }
    }

    async updateDBFromUrgencyWritersList(writersList: ChangeUrgencyWriter[]): Promise<void> {
        await this.dbReady;
        try {
            for (const writerToChange of writersList) {
                const writer = await this.localWritersDB.findOne(writerToChange.writerId).exec();
                if (writer) {
                    writer.levelOfUrgency = writerToChange.levelOfUrgency;
                    await writer.save();
                } else {
                    console.warn('Writer not found for update:', writerToChange);
                }
            }
        } catch (error) {
            console.error('Error updating writers from urgency list:', error);
        }
    }

    async updateDBFromUrgencyBookList(bookList: ChangeUrgencyBook[]): Promise<void> {
        await this.dbReady;
        try {
            for (const bookToChange of bookList) {
                const book = await this.localBooksDB.findOne(bookToChange.bookId).exec();
                if (book) {
                    book.levelOfUrgency = bookToChange.levelOfUrgency;
                    await book.save();
                } else {
                    console.warn('Book not found for update:', bookToChange);
                }
            }
        } catch (error) {
            console.error('Error updating books from urgency list:', error);
        }
    }

    async getCommunities() {
        await this.dbReady;
        try {
            const result = await this.localGeneralDB.find({ selector: { type: 'community' } }).exec();
            return result.map(doc => doc.toJSON());
        } catch (error) {
            console.error('Error getting communities:', error);
            return [];
        }
    }

    async getParchments() {
        await this.dbReady;
        try {
            const result = await this.localGeneralDB.find({ selector: { type: 'parchment' } }).exec();
            return result.map(doc => doc.toJSON());
        } catch (error) {
            console.error('Error getting parchments:', error);
            return [];
        }
    }

    async getCities() {
        await this.dbReady;
        try {
            const result = await this.localGeneralDB.find({ selector: { type: 'city' } }).exec();
            return result.map(doc => doc.toJSON());
        } catch (error) {
            console.error('Error getting cities:', error);
            return [];
        }
    }

    async getSoferReminders(levelOfUrgency: number) {
        await this.dbReady;
        try {
            const result = await this.localWritersDB.find({ selector: { levelOfUrgency } }).exec();
            return result.map(doc => doc.toJSON());
        } catch (error) {
            console.error('Error getting sofer reminders:', error);
            return [];
        }
    }
}