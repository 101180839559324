export default class CanvasRecorder {
    constructor(canvas, canvasId, fps, onStream, logError) {
        this.canvas = canvas;
        this.canvasId = canvasId;
        this.fps = fps;
        this.onStream = onStream;
        this.logError = logError;
        const stream = this.canvas.captureStream(this.fps);
        this.emitStream(stream);
    }
    restart() {
        // this.stop()
        const stream = this.canvas.captureStream(this.fps);
        this.stream = stream;
        this.emitStream(stream);
    }
    toggleLocal(stream) {
        const possibleVideoEl = document.getElementById('canvas-or-testing');
        if (possibleVideoEl) {
            document.body.removeChild(possibleVideoEl);
        }
        const video = document.createElement('video');
        video.width = 520;
        video.height = 400;
        video.id = 'canvas-or-testing';
        video.setAttribute('autoplay', 'true');
        video.setAttribute('muted', 'true');
        video.setAttribute('playsinline', 'true');
        video.crossOrigin = 'anonymous';
        document.body.appendChild(video);
        video.srcObject = stream;
        void video.play();
        video.addEventListener('error', (e) => {
            this.logError('Video error:', e);
        });
    }
    emitStream(stream) {
        if (stream) {
            return this.onStream(stream);
        }
        if (this.stream) {
            this.onStream(this.stream);
        }
        else {
            this.logError('no stream for canvas', this.canvasId);
        }
    }
    stop() {
        var _a;
        (_a = this.stream) === null || _a === void 0 ? void 0 : _a.getTracks().forEach((track) => track.stop());
        this.stream = null;
    }
}
